<template>
  <!-- 人员管理 --- 黑名单 -->
  <div style="margin-top: 20px;">
    <div class="box-card" style="text-align: left;">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="组织：">
          <el-cascader popper-class="my-cascader" filterable v-model="queryForm.organize_id" clearable checkStrictly
            :props="defaultProps" placeholder="请选择组织..." :options="organizationSeleteList" @change="onSubmit()">
          </el-cascader>
          <!-- <el-select popper-class="my-select" v-model="queryForm.organize_id" placeholder="请选择组织" clearable @change="onSubmit" filterable>
              <el-option v-for="item in organizationSeleteList" :key="item._id" :label="item.organize_name" :value="item._id"></el-option>
            </el-select> -->
        </el-form-item>
        <el-form-item label="姓名：">
          <el-input maxlength="15" v-model="queryForm.person_name" @keyup.enter.native="onSubmit()" placeholder="请输入姓名"
            @input="(e) => (queryForm.person_name = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input maxlength="11" v-model="queryForm.phone" @keyup.enter.native="onSubmit()" placeholder="请输入手机号"
            @input="(e) => (queryForm.phone = validSpace(e))"></el-input>
        </el-form-item>
        <!-- <el-form-item label="证件号码：">
            <el-input maxlength="18" v-model="queryForm.card_num" @keyup.enter.native="onSubmit()" placeholder="请输入证件号码" @input="(e) => (queryForm.card_num = validSpace(e))"></el-input>
          </el-form-item> -->
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="黑名单">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('632ab108b81f000093002526')">移出黑名单
            </lbButton>
          </div>
        </template>
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
            <!-- 使用插件生产表格 -->
            <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
            <template slot-scope="{row}" slot="register_pic">
              <el-image fit="cover" :src="row.register_pic" :preview-src-list="[row.register_pic]">
                </el-image>
            </template>
             <template slot-scope="{row}" slot="permission_id">
              {{ row.permission_id != '000000000000000000000000' ? row.permission_name : '/' }}
            </template>
             <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="err" icon="shanchu1" hint="移出黑名单" @click="delItem(row._id)"
                  v-if="isShowBtn('632ab108b81f000093002526')"></lbButton>
            </template>
          </avue-crud> -->
          <el-table class="tablestyle" ref="multipleTable" :data="tableData" tooltip-effect="dark" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
            <el-table-column label="图像" width="100">
              <template slot-scope="scope">
                <el-image fit="cover" :src="scope.row.register_pic" :preview-src-list="[scope.row.register_pic]">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="person_name" label="姓名"></el-table-column>
            <el-table-column prop="phone" label="手机号"></el-table-column>
            <el-table-column prop="selected_organize_name" label="所属组织" show-overflow-tooltip></el-table-column>
            <el-table-column prop="permission_id" label="权限组">
              <template slot-scope="scope">
                {{ scope.row.permission_id != '000000000000000000000000' ? scope.row.permission_name : '/' }}
              </template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template slot-scope="scope">
                {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <lbButton type="err" icon="shanchu1" hint="移出黑名单" @click="delItem(scope.row._id)"
                  v-if="isShowBtn('632ab108b81f000093002526')"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </le-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { getPageListByBlack, deleteByBlack, getOrganizatioTreeList } from '@/api/administrative'
export default {
  data () {
    return {
      // 查询表单
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        organize_id: '',
        person_name: '',
        phone: '',
        card_num: ''
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      // 组织下拉列表
      organizationSeleteList: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id',
        // multiple: true,可以选择多个选项
        checkStrictly: true // 可以选择父类名称进行选择
      },
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '图像',
            prop: 'register_pic',
            slot: true
          }, {
            label: '姓名',
            prop: 'person_name'
          }, {
            label: '手机号',
            prop: 'phone'
          }, {
            label: '所属组织',
            prop: 'selected_organize_name',
            width: 350
          }, {
            label: '权限组',
            prop: 'permission_id',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    this.fnGetOrganizationSeleteList()
    this.fnGetPageListByBlack()
  },
  mounted () { },
  computed: {},
  destroyed () { },
  methods: {
    // 获取组织下拉列表
    async fnGetOrganizationSeleteList () {
      const res = await getOrganizatioTreeList()
      this.organizationSeleteList = res && res.Code === 200 ? this.filterChildren(res.Data.concat()) : []
    },
    // 获取黑名单列表
    async fnGetPageListByBlack () {
      console.log('fnGetPageListByBlack----')
      var obj = this.queryForm
      obj.organize_id = this.queryForm.organize_id && this.queryForm.organize_id.length > 0 ? this.queryForm.organize_id[this.queryForm.organize_id.length - 1] : null
      const res = await getPageListByBlack(obj)
      console.log(res)
      this.total = res && res.Code === 200 ? res.Data.TotalCount : 0
      this.tableData = res && res.Code === 200 ? res.Data.DataList : []
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      console.log(this.tableData)
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetPageListByBlack()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetPageListByBlack()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetPageListByBlack()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetPageListByBlack()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetPageListByBlack()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久移出选中的黑名单人员, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // this.$msg.success('删除成功!');
          var idArr = []
          this.multipleSelection.forEach((item) => {
            idArr.push(item._id)
          })
          this.fndeleteByBlack({ _ids: idArr })
        }).catch(() => {
          this.$msg.info('已取消移出')
        })
      } else {
        this.$msg.warning('请选择要移出的黑名单人员！')
      }
    },
    async fndeleteByBlack (obj) {
      const res = await deleteByBlack(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetPageListByBlack()
    },
    // 删除当前
    delItem (id) {
      this.$confirm('此操作将永久将人员从黑名单中移出, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.fndeleteByBlack({ _ids: [id] })
        // this.$msg.success('删除成功!');
      }).catch(() => {
        this.$msg.info('已取消移出')
      })
    }
  }
}
</script>

<style scoped lang="less">.el-select {
  width: 100%;
}

.el-image {
  width: 55px;
  height: 55px;
  /deep/.el-image__error{
    background-image: url('~@/assets/img/noMessage/noPeopleInLight.png');
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    font-size:0;
  }
}
</style>
